import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SlideUpPanel from "./SlideUpPanel";

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Switch, Route, withRouter, Link } from "react-router-dom";
import { AnimatedRoute } from 'react-router-transition';
import { spring, AnimatedSwitch } from 'react-router-transition';
import patagoniaLogo from './images/shoutLogo.png';
import firebase from './firebase';
import campaingImage from './images/campaignImageDemo.png';
import upperSymbols from './images/upperSimbolsBBC.png';

function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
    };
}




export default withRouter( class AppComponent extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            search: '',
            value: 0,
            headerText: '',
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
        };
    }

    componentDidMount() {
        if (this.state.friendUID){
            var db = firebase.firestore();
            const context = this;
            db.collection('users').doc(this.state.friendUID).get()
                .then(function (doc) {
                    if (doc.exists) {
                        context.setState({
                            headerText:  doc.data().name +' te invitó al cumple de Shout. ¡Registrate e invitale un trago a tus amigos!',
                        })
                    }
                    else {
                        context.setState({
                            headerText:'¡Shout cumple años y te invita un trago gratis!',
                        })
                    }
                })
        }
        else {
            this.setState({
                headerText:'¡Shout cumple años y te invita un trago gratis!',
            })
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {

    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };
    handleSearch = (event) => {
        this.setState({search: event.target.value})
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };


    createCampaign = () => {
        var db = firebase.firestore();
        db.collection('campaigns').doc('shout').set({
            adminUID:'snXwU6W1zZeGY5FEDKx4kBS9p3g1',
            avgAge: 0,
            cincuentas: 0,
            conversions: 0,
            cuarentas: 0,
            discAmount: 0,
            endDate: "5/12/2019",
            locations:[{region: "Buenos Aires", value: 1}],
            masNoventas: 0,
            minusVeinte: 0,
            name: "Shout",
            ochentas: 0,
            otherBen: "Trago gratis",
            registers: 0,
            sesentas: 0,
            setentas: 0,
            traffic: 0,
            trafficUsers: 0,
            treintas: 0,
            veintes: 0,
            brandShare: 0,
            brandShareRegister: 0,
            consumerShare: 0,
            consumerShareRegister: 0,
            pocs: {},
            pocsData: [],
            facebookReg: 0,
            instagramReg: 0,
            storiesReg: 0,
            mailingReg: 0,
        });


    };



    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#fff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });
        return (

            <div>
                <div className={'logoContainer'}>
                    <img className={'logo'} src={patagoniaLogo}/>
                </div>
                <p className="startLoginPTitle introP">{this.state.headerText}</p>
                {/*<img src={campaingImage} className="campaignImage" alt="campaignImage"/>*/}
                <p className="startLoginPTitle introPSecond">
                    Completá tus datos, recomendá Shout y ganá un trago gratis.
                    <br/>Desde el 30/11 al 7/12 inclusive
                </p>


                <SlideUpPanel handleChangeLogin={this.props.handleChangeLogin} fid={this.props.fid} name={this.props.name} lastName={this.props.lastName} email={this.props.email} uid={this.props.uid} login={this.props.login} />

            </div>
        )
    }
})